@import "@openedx/paragon/scss/core/core";

.response-display {
  padding: map-get($spacers, 0);
  width: map-get($container-max-widths, "md");
  overflow-y: hidden;
  height: fit-content;

  .submission-files {
    .submission-files-title {
      padding: map-get($spacers, 3);
      border-radius: calc(0.375rem - 1px);
      border-bottom: 1px solid transparent;
      transition: border-color 100ms ease 150ms;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > h3 {
        margin-bottom: 0;
      }

      &.no-submissions {
        cursor: initial;

        > h3 {
          color: $gray-300;
        }
      }
    }

    .submission-files-body {
      padding: map-get($spacers, 3);
      padding-top: 0;

      .submission-files-table thead {
        display: none;
      }
    }
  }

  .preview-display {
    padding: map-get($spacers, 3) 0;
  }

  .response-display-text-content {
    white-space: pre-line;
    overflow: hidden;
  }
}

@include media-breakpoint-down(sm) {
  .response-display {
    width: 100%;

    .preview-display {
      padding: map-get($spacers, 1) 0;
    }
  }
}