@import "@openedx/paragon/scss/core/core";

// action reviews
.review-actions {
  padding: map_get($spacers, 3);
  flex-direction: row;
  background-color: $light-200;

  .review-actions-username {
    flex-grow: 1;
  }
  .review-actions-status {
    margin-left: map_get($spacers, 3);
    vertical-align: middle;
  }
  .review-actions-group {
    margin-left: 0;
    flex-shrink: 0;
    align-self: stretch;

    .submission-navigation {
      float: right;
      padding: map-get($spacers, 1);
    }
  }
}

@include media-breakpoint-down(md) {
  .review-actions {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .review-actions-username {
    padding-bottom: map-get($spacers, 3);
  }
}