@import "~@edx/brand/paragon/variables";
@import "~@openedx/paragon/scss/core/core";
@import "~@edx/brand/paragon/overrides";

.criteria-label {
  width: 100%;
  .criteria-title {
    display: inline-block;
    max-width: calc(100% - 44px);
    color: $primary-500;
    font-weight: bold;
    vertical-align: top;
  }
  .esg-help-icon {
    float: right;
    margin-top: (map-get($spacers, 2) * -1);
    margin-right: (map-get($spacers, 2\.5) * -1);
    vertical-align: top;
  }
}
.criteria-option {
  width: 100%;
  > div {
    display: inline;
    width: 100%;
    .pgn__form-label {
      display: inline-flex;
    }
    .pgn__form-control-description {
      float: right;
    }
  }
}

.criterion-feedback {
  margin-top: 1rem;
}

.popover.overlay-help-popover {
  z-index: 4000;
  margin-right: map-get($spacers, 1) !important;
  .help-popover-option {
    margin-bottom: map-get($spacers, 1);
  }
}


.grading-rubric-card {
  width: 320px !important;
  height: fit-content;
  max-height: 100%;
  margin-left: map-get($spacers, 3);
  position: sticky !important;
  top: map-get($spacers, 1) * -1;

  .grading-rubric-header {
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.3) !important;
    display: flex;
    justify-content: center;
    padding: map-get($spacers, 3);
  }

  .grading-rubric-body {
    overflow-y: scroll;
  }

  .grading-rubric-footer {
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.3) !important;
    display: flex;
    justify-content: center;
    padding: map-get($spacers, 3);
  }

  button.pgn__stateful-btn.pgn__stateful-btn-state-pending {
    opacity: .4 !important;
  }
}

@include media-breakpoint-down(sm) {
  .grading-rubric-card {
    margin-left: 0 !important;
  } 
}